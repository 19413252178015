import React from "react";
import Lottie from "react-lottie";
import happyFace from "../../assets/Lotties/carita-feliz.json";
import "./styles.css";

const Input = ({ sendQuestion, setIsOpen }) => {
  const handleSendQuestion = (question) => {
    sendQuestion(question);
  };

  const happyFaceOptions = {
    loop: true,
    autoplay: true,
    animationData: happyFace,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <form action="#" className="question_input">
      <span
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Lottie options={happyFaceOptions} height={90} width={90} />
      </span>

      <input
        type="text"
        className="question_input_text"
        placeholder="Hacer una pregunta"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSendQuestion(e.target.value);
            e.target.value = "";
          }
        }}
      />
    </form>
  );
};

export default Input;
