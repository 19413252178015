const baseUrl = "https://chatbot.dilosinpaltas.com/api";

export const fetchSinToken = async (endpoint, data, method = "GET") => {
  const url = `${baseUrl}/${endpoint}`;

  if (method === "GET") {
    const resp = await fetch(url);
    return await resp.json();
  } else {
    try {
      const resp = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      return await resp.json();
    } catch (error) {
      // console.log('Error fetch-con-token');
      return { ok: false };
    }
  }
};

export const fetchConToken = async (endpoint, data, method = "GET") => {
  const url = `${baseUrl}/${endpoint}`;
  const token = localStorage.getItem("token") || "";

  if (method === "GET") {
    return fetch(url, {
      method,
      headers: {
        "x-token": token,
      },
    });
  } else {
    try {
      const resp = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          accepts: "application/json",
          "x-token": token,
        },
        body: JSON.stringify(data),
      });

      return await resp.json();
    } catch (error) {
      // console.log('Error fetch-con-token');
      return { ok: false };
    }
  }
};

export const transformLinksInText = (text) => {
  const regex = /\[(.*?)\]\((https?:\/\/[^\s]+)\)/g;

  const transformedText = text.replace(regex, (match, linkText, linkUrl) => {
    return ` ${linkUrl}`;
  });

  return transformedText;
};
