export const topics = [
  {
    id: 1,
    title: "Métodos anticonceptivos",
  },
  {
    id: 2,
    title: "Relaciones tóxicas",
  },
  {
    id: 3,
    title: "Primera vez",
  },
  {
    id: 4,
    title: "Aborto",
  },
  {
    id: 5,
    title: "Diversidad sexual",
  },
];

export const questions = [
  {
    topicId: 1,
    text: "¿Cómo usar un condón?",
  },
  {
    topicId: 1,
    text: "¿Dónde puedo comprar condones?",
  },
  {
    topicId: 1,
    text: "¿Cómo uso la pastilla anticonceptiva?",
  },
  {
    topicId: 1,
    text: "¿Qué es el DIU?",
  },
  {
    topicId: 1,
    text: "¿Qué es la pastilla del día siguiente?",
  },
  {
    topicId: 2,
    text: "¿Cómo superar una relación tóxica?",
  },
  {
    topicId: 2,
    text: "¿Cómo identificar una relación tóxica?",
  },
  {
    topicId: 2,
    text: "¿Qué es la dependencia emocional?",
  },
  {
    topicId: 2,
    text: "¿Cuando los celos son malos?",
  },
  {
    topicId: 2,
    text: "Consejos para superar a tu ex",
  },
  {
    topicId: 2,
    text: "¿Cómo saber si estoy en una relación tóxica?",
  },
  {
    topicId: 3,
    text: "¿La primera vez duele y/o hay sangrado?",
  },
  {
    topicId: 3,
    text: "¿Puede ocurrir un embarazo en la primera vez?",
  },
  {
    topicId: 3,
    text: "¿Qué me recomiendan hacer en mi primera vez?",
  },
  {
    topicId: 3,
    text: "¿Cómo me protejo en mi primera vez?",
  },
  {
    topicId: 4,
    text: "¿Qué es el aborto?",
  },
  {
    topicId: 4,
    text: "¿Por qué se hacen abortos?",
  },
  {
    topicId: 4,
    text: "¿Cuáles son los tipos de aborto?",
  },
  {
    topicId: 4,
    text: "¿Cómo se hace un aborto?",
  },
  {
    topicId: 4,
    text: "¿Cómo afectaría ser madre a mi desarrollo profesional?",
  },
  {
    topicId: 5,
    text: "¿Debo salir del clóset?",
  },
  {
    topicId: 5,
    text: "¿Cómo reaccionar ante el rechazo?",
  },
  {
    topicId: 5,
    text: "¿Qué es la orientación sexual?",
  },
  {
    topicId: 5,
    text: "¿Qué es la identidad de género?",
  },
  {
    topicId: 5,
    text: "¿Puedo tener hijos si soy gay?",
  },
];
